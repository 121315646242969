import {
  SessionSettings,
  SessionStatus,
  Phrase,
  ClientSecret,
  RequestedData
} from '@facesignai/api'

export * from '@facesignai/api'

export interface SessionTemplate {
  id: string
  name: string
  createdAt: number
  settings: SessionSettings
}

export interface Attempt {
  clientSecret: ClientSecret
  startedAt: number
  conversation: string[]
  lastUserPhraseAppliedAt?: number
  lastAvatarPhraseAppliedAt?: number
  clientVersion?: string
  slackTs?: string
}

export interface UserParam extends RequestedData {
  value?: string | null
}

export type PhraseObject = Phrase & {
  generationLatency?: number
  avatarLatency?: number
  interruptedAt?: number
}

export interface SessionObject {
  id: string
  clientId: string
  apiKeyId: string
  createdAt: number
  startedAt?: number
  finishedAt?: number
  phrases: Record<string, PhraseObject>
  conversation: string[]
  status: SessionStatus
  settings: SessionSettings
  apiVersion?: string
  data: Record<string, string>
  usedClientSecrets: string[]
  clientSecret: ClientSecret
  attempts: Record<string, Attempt>
  slackTs?: string
  transcriptSlackTs?: string
  webhookUrl?: string
}

export type OpenAIApiRes = {
  response: string
  data: { key: string; value: string }[]
}

export type ApiKey = {
  id: string
  key: string
  expireAt?: number
  createdAt: number
  enabled: boolean
}

export type Client = {
  id: string
  title: string
  createdAt: number
  deletedAt: number
  keys: string[]
  apiKeys: Record<string, ApiKey>
  domains?: string[]
  allowStandalone?: boolean
  debugInSlack?: boolean
  webhookUrl?: string
}

export type ControlT = {
  camera: boolean
  mic: boolean
}

export interface AuthUser {
  createdAt: string
  displayName?: string
  email: string
  lastLoginAt?: string
  phoneNumber?: string
  photoURL?: string
  uid: string
  [key: string]: any
}

export type StorageFileT = {
  url: string
  size: number
  contentType?: string
}

export namespace DBT {
  export enum INTERRUPT {
    'SMART' = 'smart',
    'REGULAR' = 'regular',
    'DISABLED' = 'disabled'
  }

  export enum TRANSCRIBER {
    DEEPGRAM = 'deepgram',
    GLADIA = 'gladia',
    SPEECHMATICS = 'speechmatics',
    GOOGLE = 'google'
  }
  export interface AIPromptConfT {
    prompt: string
    model?: string
    temperature: number
    maxTokens: number
    voiceId?: string
    jsonScheme?: string
    cannedResponse?: Record<string, string>
    cannedResponseTranslates?: Record<string, Record<string, string>>
    interruptionMode?: INTERRUPT
    initialPhrase?: string
    initialPhraseTranslates?: Record<string, string>
    finalPhrase?: string
    finalPhraseTranslates?: Record<string, string>
    transcriber?: TRANSCRIBER
  }

  export interface AIConfTemplateT {
    id: string
    name: string
    createdAt: number
    conf: AIPromptConfT
  }

  export interface AICFQT {
    prompt: string
    model: string
    temperature: number
    maxTokens: number
  }

  export interface AIConfT {
    CFQ: AICFQT
    answerAnalysis: AIPromptConfT
    responseAnalysis: AIPromptConfT
    CFQSuggestions: AICFQT
  }

  export type ScreenshotT = {
    id: string
    createdAt: number
    url: string
    isLast: boolean
    isFirst: boolean
  }

  export type LangT = {
    id: string
    title: string
    createdAt: number
    isDisabled?: boolean
  }

  export type FaceT = {
    id: string
    createdAt: number
    url: string
  }

  export type VegasUserT = Record<string, any> & {
    id: string
    faces: Record<string, FaceT>
  }
}
